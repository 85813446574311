import { memo } from "react"

import { ReactComponent as CoinsIcon } from "@spatialsys/assets/icons/coins.svg"
import { ReactComponent as MultiPlatformIcon } from "@spatialsys/assets/icons/multi-platform.svg"
import { ReactComponent as UnityIcon } from "@spatialsys/assets/icons/unity.svg"
import { Heading, Text } from "@spatialsys/web/ui"

const features = [
  {
    name: "Creator Toolkit",
    Icon: UnityIcon,
    description: "Supercharge Unity creations with our tools and templates.",
  },
  {
    name: "Virtual Economy",
    Icon: CoinsIcon,
    description: "Sell digital goods with top-tier revenue share.",
  },
  {
    name: "Multi-Platform",
    Icon: MultiPlatformIcon,
    description: "Publish games everywhere across web, mobile, and VR.",
  },
]

export const PlatformFeatures = memo(function PlatformFeatures() {
  return (
    <div className="grid gap-10 md:grid-cols-3 md:gap-16">
      {features.map(({ name, Icon, description }) => (
        <div key={name} className="flex max-w-[260px] flex-col items-center text-center">
          <Icon className="h-24" />
          <Heading asChild className="mt-6 text-h4 md:text-h3" textAlign="center">
            <h3>{name}</h3>
          </Heading>
          <Text className="mt-2 text-base opacity-60 sm:text-lg">{description}</Text>
        </div>
      ))}
    </div>
  )
})
